:root {
    --font-color: #3f6aaa;
    --border-color: #d4e8ff;
    --border-radius: 3px;
    --button-border-radius: 5px;
    --form-font-size: 20px;
    --color-primary: #3574f3;
    --color-warning: #ffae21;
    --color-danger: #ff3021;
    --color-success: #46c829;
    --color-disabled: #999999;
    --success-card: #46c829;
    --navbar-height: 40px;
    --font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
        Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    font-family: var(--font-family);
}

.bold {
    font-weight: bold;
}

.font-color {
    color: var(--font-color);
}
.font-success {
    color: var(--color-success);
}
.font-primary {
    color: var(--color-primary);
}
.font-warning {
    color: var(--color-warning);
}
.font-danger {
    color: var(--color-danger);
}

.loader-conteiner {
    top: 0;
    left: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background-color: #fff;
    width: 100vw;
    height: 100vh;
}

.list {
    color: var(--font-color);
}

.list ol {
    padding-left: 40px;
    color: var(--font-color);
}

.list ol li {
}

.loader {
    width: 20px;
    height: 20px;
    position: relative;
    left: -50px;
}

.loader::before,
.loader::after {
    content: '';
    position: absolute;
    height: inherit;
    width: inherit;
    border-radius: 50%;
    mix-blend-mode: multiply;
    animation: rotate 1s infinite cubic-bezier(0.77, 0, 0.175, 1);
}

.loader::before {
    background-color: #fc3f9e;
}

.loader::after {
    background-color: #50e8f3;
    animation-delay: 0.5s;
}

.loader-conteiner .message {
    padding: 20px;
    color: var(--font-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.preview-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}
.preview-loader .loader {
}

@keyframes rotate {
    0%,
    100% {
        left: 95px;
    }

    25% {
        transform: scale(0.3);
    }

    50% {
        left: 0;
    }

    75% {
        transform: scale(1);
    }
}
.color-warning {
    color: var(--color-warning);
}

.color-primary {
    color: var(--color-primary);
}

.color-danger {
    color: var(--color-danger);
}

.main {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
}

.resumes {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.resume-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
}

.resume-header .positions {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    height: 15px;
    align-items: center;
}

.resume-header .positions .position {
    width: 5px;
    height: 5px;
    background-color: #aaa;
    border-radius: 50%;
    margin: 0 15px;
}

.resume-header .positions .selected {
    background-color: var(--color-primary);
    width: 10px;
    height: 10px;
}

.navbar {
    background-color: #fff4;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--navbar-height);
    z-index: 20;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.2);
    /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(201, 215, 252, 0.3);
    font-size: 14px;
}

.navbar > div {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--font-color);
}
.navbar i {
    padding: 0 3px;
}

.navbar .go-back {
    cursor: pointer;
    width: 70px;
    color: var(--color-primary);
    font-weight: bold;
}

.navbar .go-back button {
    padding: 4px;
}

.navbar .close {
    width: 50px;
    color: var(--color-danger);
    cursor: pointer;
    font-weight: bold;
}

.navbar h3 {
    font-family: var(--font-family);
}

.container {
    flex: 1;
    flex-direction: column;
    display: flex;
    max-width: 90%;
}

form {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.form-group {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 2px 0;
    font-size: 14px;
    margin-top: 14px;
}

.form-group-range {
    border: 1px solid var(--border-color);
    padding: 5px;
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
}

.form-group .desc {
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 18px;
}

.form-group .date {
    color: gray;
}

.form-group-range input[type='range'] + label {
    font-weight: bold;
    text-align: center;
    font-size: 20px;
}
.form-group ul {
    /* padding: 30px; */
}

.form-group li {
    font-family: var(--font-family);
    /* padding: 10px; */
}
.form-group li b {
    font-weight: bold;
}

.row {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}

.choose-product {
}

.choose-plan {
    display: flex;
    justify-content: space-between;
}
.choose-plan .plan {
}

.col {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.form-group label {
    color: var(--font-color);
    font-family: var(--font-family);
    margin: 3px 0;
    background-color: #fff;
    width: fit-content;
    padding: 0 3px;
    top: -15px;
    left: 10px;
    font-weight: 600;
    position: absolute;
}

.form-group input,
select {
    font-size: var(--form-font-size);
    border-radius: var(--border-radius);
    padding: 5px;
    font-size: 14px;
    border: 2px solid var(--border-color);
    color: var(--font-color);
    font-weight: 400;
}

.form-group select:focus {
    border: var(--color-primary);
}

.form-group input:focus {
    background-color: #fff;
}

.form-group .error {
    color: red;
    margin: 5px 0;
    font-size: 14px;
    font-family: var(--font-family);
}

.form-group input:focus,
.form-group textarea:focus {
    outline: none;
    border-color: var(--color-primary) !important;
    box-shadow: inset 4px 4px 6px rgba(174, 174, 192, 0.2),
        inset -4px -4px 4px rgba(255, 255, 255, 0.7);
}

.form-group input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #bdbdbd;
    font-size: 12px;
}

.form-group textarea {
    font-size: var(--form-font-size);
    border-radius: var(--border-radius);
    padding: 10px;
    border: 2px solid var(--border-color);
}
@media only screen and (min-width: 600px) {
    .form-group input::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        font-size: 16px;
    }
}
/* small screens controll */
#root > div > div.resumes > div > form > div.row > div > input[type='text'] {
    width: 85%;
}

/* School List */
.editing {
    background-color: var(--color-warning);
    color: #fff;
    font-size: 18px;
    border-radius: var(--border-radius);
    padding: 0 5x;
}
.school-list {
    padding: 0 0 15px 0;
}

.school-list .school {
    position: relative;
    border: 1px solid #abc7ff7c;
    background-color: rgba(225, 236, 250, 0.473);
    border-radius: var(--border-radius);
    font-family: var(--font-family);
    color: var(--font-color);
    font-size: 12px;
    margin-top: 5px;
}

.school-list .school .school-name {
}

.school-list .school .grade {
}

.school-list .school .col:nth-child(1) {
    flex: 1;
    padding-right: 10px;
}
.school-list .school .col:nth-child(2),
.skill-list .skill .col2 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
}
.school-list .school .interval {
    display: flex;

    flex-direction: row;
    justify-content: space-between;
}

.school-list .school > * {
    margin: 5px;
}

.school-list .school .col i {
    background-color: white;
    border-radius: 50%;
    font-size: 30px;
}
.school-list .school .col .fa-edit {
    color: var(--color-primary);
    border-radius: 2px;
}
.school-list .school .col .action,
.skill-list .skill .col .action {
    font-size: 12px;
    background-color: #fff;
    padding: 2px;
    color: #fff;
    border: 1px solid #fff;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: 0.5s ease;
    font-weight: bold;
}

.school-list .school .col .action:hover {
    transition: 0.5s ease;
    transform: scale(1.04);
}

.school-list .school .col .edit,
.skill-list .skill .col .edit {
    background-color: var(--color-primary);
}

.school-list .school .col .delete,
.skill-list .skill .col .delete {
    background-color: var(--color-danger);
}

.school-list .school .col .fa-circle-minus {
    color: var(--color-danger);
}
/* Button */

.button {
    background: none;
    color: var(--font-color);
    border: 2px solid var(--border-color);
    padding: 5px;
    border-radius: var(--button-border-radius);
    font-size: 14px;
    width: fit-content;
    cursor: pointer;
    transition: all ease 0.5s;
}

.button:hover {
    transition: all ease 0.5s;
    transform: scale(1.1);
}

.button-primary {
    color: #fff;
    background-color: var(--color-primary);
    border: none;
}

.button-warning {
    color: #fff;
    background-color: var(--color-warning);
    border: none;
}

.button-danger {
    color: #fff;
    background-color: var(--color-danger);
    border: none;
}

.button-success {
    color: #fff;
    background-color: var(--color-success);
    border: none;
}

.button-disabled {
    color: #fff;
    background-color: var(--color-disabled);
    border: none;
}

.button-success-border {
    color: var(--color-success);
    border: 2px solid var(--color-success);
    font-weight: bold;
}

.button-primary-border {
    color: var(--color-primary);
    border: 2px solid var(--color-primary);
    font-weight: bold;
}

@media only screen and (min-width: 600px) {
    .container .form-group button {
        max-width: 200px;
        margin-left: calc(50% - 100px);
        font-size: 20px;
        padding: 10px;
    }

    .form-group button .google-login {
        max-width: none;
    }

    .form-group {
        position: relative;
        font-size: 18px;
    }
    .form-group input {
        font-size: 20px;
        padding: 10px;
    }
}

.form-group .add-new {
    margin-top: 50px;
}

.form-group .button {
    width: auto;
}

.reminder {
    margin: 20px;
    font-family: var(--font-family);
    color: var(--color-warning);
}

.next-step {
    /* margin: 50px 0; */
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    font-family: var(--font-family);
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    z-index: 20;
}

.next-step .icon {
    border-radius: 50%;
    /* padding: 20px; */
    font-size: 46px;
    font-weight: bold;
}

.next-step .preview,
.next-step .next {
    /* background-color: rgba(255, 255, 255, 0.5); */
    padding: 5px;
    display: flex;
    justify-content: space-around;
    flex: 1;
    font-size: 18px;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(10px);
    cursor: pointer;
    /* border: 1px solid gray; */
    padding: 10px;
    text-transform: uppercase;
    opacity: 0.8;
}

.next-step .next {
    color: #fff;
    background-color: var(--color-success);
    border-color: var(--color-success);
}

.next-step .preview {
    background-color: var(--color-primary);
    /* border-color: var(--color-primary); */
}

@media only screen and (min-width: 800px) {
    .next-step > div {
        position: fixed;
        top: 240px;
        border-radius: 5px;
        gap: 10px;
        /* color: rgb(112, 29, 29); */
        transition: all ease 0.5s;
        cursor: pointer;
    }

    .next-step > div:hover {
        transform: scale(1.1);
        transition: all ease 0.5s;
    }
    .next-step .preview {
        left: 5px;
    }

    .next-step .next {
        right: 5px;
    }

    .next-step .preview,
    .next-step .next {
        /* border: 1px solid #999; */
        /* color: #999; */
    }

    .next-step .preview:hover {
        background: var(--color-primary);
        color: #fff;
        opacity: 1;
    }

    .next-step .next:hover {
        background-color: var(--success-card);
        color: #fff;
    }
}

@media only screen and (min-width: 1200px) {
    .next-step .preview {
        left: 50px;
    }

    .next-step .next {
        right: 50px;
    }
}

/* Radio button */
.form-group input[type='radio']:checked + label {
    border-color: var(--color-primary);
    color: var(--color-primary);
    border-width: 2px;
}

.form-group input[type='radio'] {
    display: none;
}

.form-group input[type='radio'] + label {
    border: 1px solid var(--border-color);
    padding: 5px;
    border-radius: var(--border-radius);
    display: block;
    font-size: 14px;
    font-weight: 300;
}

/* Skill list */

.skill-list {
}

.skill-list .skill {
    position: relative;
    border: 1px solid #abc7ff7c;
    background-color: rgba(225, 236, 250, 0.473);
    border-radius: var(--border-radius);
    font-family: var(--font-family);
    color: var(--font-color);
    font-size: 12px;
    margin-top: 5px;
}

.skill-list .skill .desc {
    font-weight: bold;
}

.skill-list .skill .level {
    /* background-color: pink; */
}

.skill-list .skill .interval {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.skill-list .skill > * {
    margin: 5px;
}

.skill-list .skill .check {
    background-color: white;
    border-radius: 50%;
    font-size: 30px;
    color: var(--color-danger);
}

.skill .drop-item {
    position: absolute;
    font-size: 10px;
    top: -10px;
    left: -30px;
    padding: 1em;
    border-radius: 50%;
    background-color: var(--color-danger);
}

/*  Personal info */
.personal-info {
    position: relative;
    background-color: var(--success-card);
    border-radius: var(--border-radius);
    padding: 10px;
    color: #fff;
    font-family: var(--font-family);
}

.personal-info .info {
    display: flex;
    gap: 10px;
    font-weight: 300;
    margin-top: 5px;
}

/* Dashboard */

.dashboard {
    font-family: var(--font-family);
    width: 90%;
    color: #585858;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.dashboard .body {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.dashboard .body h2 {
    font-size: 24px;
}
.dashboard .resume-type {
    background-color: #fff;
    height: 3px;
    min-width: 200px;
}

.dashboard .resume-new {
    background-color: #339dff;
}

.dashboard .resume-expired {
    background-color: #8f8e8e;
}

.dashboard .resume-valid {
    background-color: #46c829;
}
.new-resume {
    position: relative;
    border-radius: 7px;
    padding: 0;
    cursor: pointer;
    outline-offset: 4px;
    border: 2px dashed #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 120px;
    flex-direction: column;
    width: 240px;
    transition: all ease 0.5s;
}
.new-resume:hover {
    border-color: #339dff;
}

.new-resume .new-plus {
    font-size: 30px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    align-items: center;
    border: 2px dashed #339dff;
    color: #339dff;
}

.new-resume .new-text {
    margin-top: 20px;
    color: #339dff;
    font-weight: bold;
}

.resume-list {
    margin-top: 10px;
    color: gray;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.resume-list .item {
    position: relative;
    width: 240px;
    height: 300px;
    box-shadow: 2px 2px 4px 1px #89c0f33d;
    border: 1px solid #c8d6fcc7;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    color: var(--font-color);
    overflow: hidden;
}
.resume-list .item .payment-checked {
    z-index: 5;
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 50px;
    color: var(--color-success);
    opacity: 0.8;
}

.resume-list .item .screenshoot {
    flex: 1;
    background-size: cover;
    cursor: pointer;
    overflow: hidden;

    display: flex;
}

.resume-list .item .resume-preivew {
    position: absolute;
    transform: scale(0.31);
    left: -277px;
    top: -386px;
    z-index: 1;
}

.resume-list .item .view-back {
    flex: 1;
    display: flex;
    width: 100px;
    height: 300px;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease;
    z-index: 50;
}
.resume-list .item .view-back:hover {
    background-color: #d9fbff8c;
}

.resume-list .item .view-back .view {
    background-color: #339dff;
    padding: 5px;
    border-radius: 5px;
    color: #fff;
    opacity: 0.9;
    font-size: 20px;
}

.resume-list .item .view-back:hover .view {
    opacity: 1;
    background-color: #339dff;
}

.resume-list .item .tools {
    padding: 10px;
    box-shadow: #8f8e8e 0px 1px 30px 2px;
    z-index: 2;
    background-color: #fff;
}

.resume-list .item .desc {
    color: #2f4c7a;
    font-weight: 400;
}

.resume-list .item .date {
    margin-top: 5px;
    font-size: 14px;
    color: #aaa;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
}
.resume-list .item .tools .download {
    padding: 10px 0;
    color: var(--color-primary);
    border-top: 2px solid #e6e5e5;
    display: none;
}

@media only screen and (min-width: 560px) {
    .resume-list {
        /* justify-content: flex-start; */
    }
    .new-resume {
        height: 300px;
        margin: 0;
    }
}

/* REVIEW */

.review {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    font-family: var(--font-family);
}

.review h2 {
    font-size: 18px;
    font-family: var(--font-family);
    margin: 10px 0;
    color: var(--color-warning);
}

.review .content {
    display: none;
}

.review .form-group {
    /* border-bottom: 1px solid rgb(214, 214, 214); */
    /* width: 80%; */
}

.review .form-group .row .button {
    font-size: 14px;
    padding: 4px;
}

.content {
    padding: 0;
    margin: 0 auto;
    font-family: var(--font-family);
}

.centerplease {
    margin: 0 auto;
    font-size: 40px;
}

/*Question*/
.question {
    position: relative;
    background: #e9e9e9;
    font-weight: bold;
    margin: 0;
    padding: 10px 10px 10px 50px;
    display: block;
    cursor: pointer;
    color: #444;
    font-size: 20px;
    font-family: var(--font-family);
}

/*Answer*/
.answers {
    padding: 0px 15px;
    margin: 5px 0;
    /* width: 100% !important; */
    height: 0;
    overflow: hidden;
    z-index: -1;
    position: relative;
    opacity: 0;
    -webkit-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease;
}

.questions:checked ~ .answers {
    height: auto;
    opacity: 1;
    padding: 15px;
}

/*FAQ Toggle*/
.plus {
    position: absolute;
    margin-left: 10px;
    z-index: 5;
    font-size: 2em;
    line-height: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease;
}

.questions:checked ~ .plus {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.questions {
    display: none;
}

.modal-download {
    font-family: var(--font-family);
    position: fixed;
    z-index: 50;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: #ffffffc3;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-download .download {
    font-size: 18px;
}

.modal-download .close {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 5px;
    border-radius: 5px;
    border: 2px solid #fff;
    color: #fff;
    font-family: var(--font-family);
    background-color: var(--color-danger);
    cursor: pointer;
}

.modal-confirm-name {
    position: fixed;
    display: flex;
    bottom: 0;
    left: 0;
    z-index: 20;
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);

    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.confirm-name {
    border: 2px solid var(--color-warning);
    background-color: #fff;
    width: 90%;
    max-width: 600px;
    padding: 5px;
    border-radius: 5px;
}

.confirm-name .name {
    margin-top: 20px;
}

.confirm-name .row {
    margin-top: 20px;
}

.modal-confirm-name .confirm-name-question {
    position: relative;
    color: var(--color-warning);
}

.cv-preview-wrapper {
    position: relative;
    width: 200px;
    height: 450px;
    display: flex;
}

.cv-preview {
    position: relative;
    top: -340px;
    left: -300px;

    border: 1px solid gray;
    min-width: 794px;
    transform: scale(0.4);
}

.products-wrapper {
    text-align: center;
}
.products-wrapper .product {
    border-width: 2px;
    border-style: solid;
    padding: 20px;
    max-width: 240px;
    border-radius: 10px;
    /* box-shadow: 1px 1px 5px 1px #cfcfcf; */
}
.products-wrapper .product .title {
    font-size: 30px;
    font-weight: bold;
}

.products-wrapper .product button {
    margin: 20px 0;
    width: 200px;
    font-size: 20px;
}

.products-wrapper .product .product-body .product-detail {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding: 5px 0;
    color: #666;
}
.products-wrapper .product .product-body .product-detail i {
    color: #46c829;
}

.products-wrapper .product .product-body .product-detail p {
    text-align: left;
    padding-left: 10px;
}

@media only screen and (min-width: 375px) {
    .cv-preview-wrapper {
        height: 510px;
    }
    .cv-preview {
        top: -315px;
        transform: scale(0.45);
    }
}
@media only screen and (min-width: 410px) {
    .cv-preview-wrapper {
        height: 580px;
    }
    .cv-preview {
        top: -285px;
        transform: scale(0.5);
    }
}
@media only screen and (min-width: 768px) {
    .cv-preview-wrapper {
        height: 580px;
    }

    .cv-preview {
        top: -280px;
        left: -300px;
        transform: scale(0.5);
    }

    .review .row {
        /* top: ; */
    }
}
@media only screen and (min-width: 768px) and (min-height: 800px) {
    .cv-preview-wrapper {
        height: 580px;
    }

    .cv-preview {
        top: -220px;
        left: -300px;
        transform: scale(0.6);
    }
}

.logout {
    display: flex;
    position: fixed;
    right: 10px;
    top: 5px;
    padding: 5px;
    font-size: 30px;
    cursor: pointer;
    z-index: 10;
}

.vagas {
    display: flex;
    position: absolute;
    right: 70px;
    top: 5px;
    padding: 5px;
    font-size: 30px;
    cursor: pointer;
    display: none;
}

.vagas a {
    height: auto;
}

.logout .user-menu {
    background-color: #fff;
    position: absolute;
    top: 40px;
    padding: 10px;
    right: 10px;
    display: none;
    z-index: 10;
    box-shadow: 1px 1px 3px 5px #8d8d8d3f;
    border-radius: 5px;
}
.logout .user-menu .menu-item {
    padding: 5px 10px;
}

.logout .user-menu .menu-item:hover {
    color: var(--color-primary);
}

.logout:hover .user-menu {
    display: block;
}

.photo {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.controls {
    position: fixed;
    bottom: 100px;
    width: 100%;
    left: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.controls input {
    z-index: 1000;
    margin: 10px 0;
}

.file-input {
    margin-top: 20px;
    border-radius: 5px;
    padding: 20px 10px;
    border: 2px dashed #cfcfcf;
}

.croped-photo {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.croped-photo img {
    max-width: 200px;
    box-shadow: 2px 3px 5px 5px #cfcfcf;
}

.change-photo {
    position: fixed;
    font-family: var(--font-family);
    z-index: 10;
    width: 100%;
    left: 0;
    top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about textarea {
    font-family: var(--font-family);
    color: var(--font-color);
}

.about .photo {
    width: 100%;
    margin: 10px 0;
}

.about .photo img {
    box-shadow: 2px 5px 5px gray;
    max-width: 200px;
}

/*************TextEditor****************/
.wrapper-editor {
    border: 2px solid var(--border-color);
    padding: 5px;
    border-radius: var(--border-radius);
    font-family: var(--font-family);
}

/*************Menu****************/
.menu {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffffc3;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
}

.menu .container {
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;
}

.menu-list {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.menu-list .menu-item {
    background-color: #ffffff;
    padding: 10px;
    margin: 10px;
    font-family: var(--font-family);
    font-size: 18px;
    color: var(--color-primary);
    text-transform: uppercase;
    cursor: pointer;
    transition: all ease 0.5s;
    flex: 1;
    display: flex;
    width: 100%;
    justify-content: center;
    border: 1px solid #93b7ff;
    border-radius: 3px;
}

.menu-list .menu-item:hover {
    transition: all ease 0.5s;
    box-shadow: inset 4px 4px 6px rgba(174, 174, 192, 0.2),
        inset -4px -4px 4px rgba(255, 255, 255, 0.7);
}

.menu .close {
    font-family: var(--font-family);
    position: absolute;
    right: 20px;
    top: 20px;
    background-color: var(--color-danger);
    padding: 3px;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
}

@media only screen and (min-width: 600px) {
    .container {
        max-width: 500px;
    }
}

/***********LOGIN**************/

.login-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    min-width: 300px;
    min-height: 600px;
}

.login-container .form-group {
    min-width: 300px;
    display: flex;
    /* justify-content: center; */
}

.login-container .form-group label {
    /* text-align: center; */
}

.ilustration {
    position: relative;
    width: 240px;
    display: flex;
    justify-content: center;
}

.logo {
    font-size: 56px;
    font-weight: bold;
    color: #aaa;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: var(--font-family);
}

.google-login {
    width: 100%;
    box-shadow: none !important;
    border: 1px solid #ddd !important;
    border-radius: var(--border-radius) !important;
    overflow: hidden !important;
}

.ilustration img {
    width: 100%;
}

.login-form {
    margin-top: 50px;
}

.register-header {
    font-family: var(--font-family);
}

.register-header h2 {
    margin: 20px 0;
    color: #000066;
    font-size: 24px;
    font-weight: bold;
}

.login-footer {
    /* position: absolute; */
    /* bottom: 0; */
    /* padding: 10px 40px; */
}

/****************TEMPLATES*********************/
.templates-container {
    width: 90%;
    max-width: 1000px;
}

.templates {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    position: relative;
}

.template {
    position: relative;
    min-height: 200px;
    width: 48%;
    max-width: 200px;
    cursor: pointer;
    transition: all ease 1s;
}

.template:hover {
    transform: scale(1.1);
    transition: all ease 1s;
}

.template img {
    width: 100%;
    box-shadow: 2px 4px 5px 1px #1d5ddd63;
}

.view-template {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(68, 68, 68, 0.445);
    z-index: 60;
    backdrop-filter: blur(5px);
    flex-direction: column;
}

.view-template img {
    width: 70%;
    max-width: 400px;
}
.view-template .template-navigation {
    position: absolute;
    width: 100%;
    height: 20px;
    left: 0;
    top: 50%;
    z-index: 20;
    display: flex;
    justify-content: space-between;
}
.view-template .template-navigation div {
    background-color: var(--color-primary);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bold;
    border: 2px solid #fff;
}

.view-template .buttons {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

/****************Review*********************/
.review-actions {
    position: fixed;
    bottom: 0;
    background-color: rebeccapurple;
    width: 100%;
    display: flex;
}
.review-actions .generate,
.review-actions .edit,
.review-actions .change-template-footer,
.review-actions .change-language-footer {
    flex: 1;
    color: #fff;
    border: none;
    font-size: 24px;
    padding: 10px;
    cursor: pointer;
}

.review-actions .generate {
    background-color: var(--color-success);
}

.review-actions .generate-disabled {
    background-color: var(--color-disabled);
}

.review-actions .edit {
    background-color: var(--color-primary);
}

.review-actions .change-template-footer {
    background-color: #007e9f;
    display: none;
}

.review-actions .change-language-footer {
    background-color: #2fb4ca;
    display: none;
}

@media only screen and (min-width: 768px) {
    .review-actions .change-template-footer,
    .review-actions .change-language-footer {
        display: block;
    }
    .change-template-button {
        display: none;
    }
}

/**********New Resume***********/

.tutorial {
}
.tutorial ul {
    padding: 10px;
}

.tutorial ul li {
    margin-left: 10px;
    padding: 5px 0;
}

/**********User List***********/

.user-list {
}
.user-list .item {
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    margin-top: 5px;
    padding: 5px;
}

.user-list .item .desc {
    font-weight: bold;
}

/**********Extras***********/

.over-close {
    height: 50px;
    width: 50px;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 20;
    cursor: pointer;
}

/**********Copyright***********/

.copyright {
    color: var(--font-color);
}
